import React from "react"
import { Link } from "gatsby"

const Contacts = () => (

    <div className="sidenav">
        <ul>
          <li>
            <Link to ="/">zur Startseite</Link>
          </li>
        </ul>
        <h1>Zur Person</h1>
        <ul>
          <li>
            <Link to ="/katharina/">Über mich</Link>
          </li>
          <li>
            <Link to ="/kontakt/">Kontakt / Impressum</Link>
          </li>
        </ul>
        <h1>Meine Bücher</h1>
        <ul>
          <li>
            <Link to ="/backbuch1/">Backbuch - Band I</Link>
          </li>
          <li>
            <Link to = "/backbuch2/">Backbuch - Band II</Link>
          </li>
          <li>
            <Link to = "/kochbuch/">Kochbuch</Link>
          </li>
          <li>
            <Link to ="/einweckbuch/">Einweckbuch</Link>
          </li>
          <li>
            <Link to ="/leseprobe/">Leseprobe</Link>
          </li>
        </ul>
        <h1>Buch kaufen</h1>
        <ul>
          <li>
            <Link to ="/bestellung/">Bestellung</Link>
          </li>
          <li>
            <Link to ="/agb/">AGB</Link>
          </li>
        </ul>
      </div>    
)

export default Contacts