import React, { Component } from "react"
import { Helmet } from "react-helmet"

export default class Head extends Component {
  render() {
    return (
      <div className="Head">
        <Helmet>
          <meta
            httpEquiv="content-type"
            content="text/html; charset=iso-8859-1"
          />

          <meta
            name="description"
            content="Siebenbürgische Kochrezepte - Koch- und Backbücher von Katharina Gabber"
          />

          <meta
            name="keywords"
            content="Kochbuch,Kochrezepte,Siebenbürgen,siebenbürgisch,Backbuch"
          />

          <meta name="author" content="Katharina Gabber" />

          <link
            rel="stylesheet"
            type="text/css"
            href="default.css"
            media="screen"
          />
          <title>Katharinas Rezepte: siebenbürgische Kochbücher</title>
        </Helmet>
      </div>
    )
  }
}
